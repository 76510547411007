.Modal {
    z-index: 11;
    padding: 3em;
    width: 55%;
    max-height: 70%;
    background-color: #fff;
    opacity: 1;
    text-align: left;
}
.Modal h1, h2, h3, h4, h5, h6 { margin-top: 0; }
.Modal-overlay {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
}

@media screen and (max-width: 640px) {
    .Modal { width: 100%; }
}