.Survey { height: 90vh; overflow-y: auto; }
.Survey-form { padding: 2em 1.5em !important; }
.Survey-range::before { content: "0"; padding: 10px; }
.Survey-range::after { content: "5"; padding: 10px; }
.Survey h5 { margin-bottom: 2em; }
.Survey h6 { margin: 2em 0; }

/* Scrollbar */
.Survey::-webkit-scrollbar { width: 10px; background-color: #F5F5F5; }
.Survey::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); border-radius: 10px; background-color: #F5F5F5;}
.Survey::-webkit-scrollbar-thumb { border-radius: 10px; -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); background-color: #7cb342 }