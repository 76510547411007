body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: hidden;
}

a { color: inherit; text-decoration: inherit; }
code { font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.halign { position: absolute !important; left: 50% !important; transform: translateX(-50%) !important; }
.valign { position: absolute !important; top: 50% !important; transform: translateY(-50%) !important; }
.halign.valign { transform: translate(-50%, -50%) !important; }
.text-center { text-align: center }
.text-left { text-align: left }
.text-right { text-align: right }

@media screen and (max-width: 640px) {
    .optional { display: none; }
    .halign { left: 0 !important; }
    .halign.valign { transform: translateY(-50%) !important; }
}