tbody tr { cursor: pointer; }

/* tbody overflow-y */
table { width: 100%; }
thead { width: calc(100% - 1em); }
tbody { display: block; height: 75vh; overflow-y: auto; }
thead,
tbody tr { display: table; width: 100%; table-layout: fixed; }
/* Scrollbar */
tbody::-webkit-scrollbar { width: 10px; background-color: #F5F5F5; }
tbody::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); border-radius: 10px; background-color: #F5F5F5;}
tbody::-webkit-scrollbar-thumb { border-radius: 10px; -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); background-color: #7cb342 }

@media screen and (max-width: 640px) {
    tbody { height: 65vh; }
}